import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link as GLink, navigate } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Copyright from "../components/Copyright";
import Logo from "../components/Logo";
import useStyles from "../helpers/styles";
import Wrapper from "../helpers/FormWrapper";
import * as CognitoService from "../services/cognito.service.js";
import "../helpers/getDestUrl";

const SigninForm = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [pwd, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location);
      const referrer = url.searchParams.get("ref");

      if (referrer) {
        sessionStorage.setItem("referrer", referrer);
        url.searchParams.delete("ref");
        window.history.pushState(null, "", url.toString());
      }
    }
  }, []);

  const onSubmit = () => {
    const user = {
      email: email,
      password: pwd,
    };

    CognitoService.signin(user)
      .then(function (result) {
        if (typeof window !== "undefined") {
          let returnUrl = "";

          if (typeof window !== "undefined") {
            if (sessionStorage.getItem("referrer"))
              returnUrl = sessionStorage.getItem("referrer");
            else returnUrl = "https://mapquest.com/";
          }

          window.location = returnUrl + "?jwtToken=" + result.idToken.jwtToken;
        }
      })
      .catch(function (error) {
        if (error.code === "PasswordResetRequiredException") {
          setEmailError("Please Reset your password");
          setTimeout(() => {
            navigate("/reset-password");
          }, 2500);
        } else if (error.code === "UserNotConfirmedException") {
          setEmailError("You need to confirm your account");
          setTimeout(() => {
            navigate("/confirmation");
          }, 2500);
        } else {
          setEmailError("Incorrect username or password.");
        }
      });

    return false;
  };

  return (
    <div className={classes.paper}>
      <Logo />
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        noValidate
      >
        <TextField
          variant="outlined"
          required
          fullWidth
          error={Boolean(emailError)}
          helperText={emailError}
          id="email"
          label="Email Address"
          name="email"
          onChange={({ target }) => {
            setEmail(target.value);
          }}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          onChange={({ target }) => {
            setPassword(target.value);
          }}
          label="Password"
          type="password"
          id="password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          onClick={onSubmit}
          fullWidth
          variant="contained"
          color="primary"
          className="submit-btn"
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs={12}>
            <GLink
              to="/reset-password"
              className={classes.primary}
              variant="body2"
            >
              Forgot password?
            </GLink>
          </Grid>
          <Grid item xs={12}>
            <GLink to="/signup" className={classes.primary} variant="body2">
              {"Don't have an account? Sign Up"}
            </GLink>
          </Grid>
        </Grid>
        <Box className={classes.box} mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
};

export default function SignIn() {
  return (
    <Wrapper>
      <SigninForm />
    </Wrapper>
  );
}
